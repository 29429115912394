//==============================================================================
// Displays a progress bar that is filled according to an externally supplied object
// It uses a <div> and adjusts the width rather than using a <progress> element
//
// This is a bit hacky in that it uses an observable object rather than a value
// It would be difficult for an external observer to modify a value, but a
// field in an object is fine.
// The field is arbitrarily hard-coded to "progress". It would be better to
// accept the field name as a property.
//==============================================================================
import * as React from 'react';
import { observer } from 'mobx-react';
import { observable } from 'mobx';

//==============================================================================
//==============================================================================
interface hasProgress {
    progress: number;
}

export interface ProgressBarProps {
    className: string;
    status: hasProgress;
}

//==============================================================================
// Function Component
//==============================================================================
@observer
class ProgressBar extends React.Component<ProgressBarProps> {

    @observable private progress: hasProgress;

    //----------------------------------------------------------
    //----------------------------------------------------------
    constructor(props: ProgressBarProps) {
        super(props);

        this.progress = props.status;
    }

    //----------------------------------------------------------
    //----------------------------------------------------------
    public render() {
        const percentComplete = `${Math.floor(this.progress.progress * 100)}%`;

        return (
            <div className={this.props.className}>
                <div className={`${this.props.className}__inner`} style={{width: percentComplete}}>
                    &nbsp;
                </div>
            </div>
        );
    }

}

export default ProgressBar;
