//==============================================================================
// Drag and drop target for file uploads
//
// This is meant to work in conjunction with the upload-button component, but
// the button isn't explicitly included here to allow for better control of
// positioning and styling.
//==============================================================================
/* eslint-disable react/jsx-no-bind */

import * as React from 'react';
import { observer } from 'mobx-react';
import { observable } from 'mobx';
import classnames from 'classnames';

//==============================================================================
//==============================================================================
export interface UploadBoxProps {
    className: string;
    initiateUpload: (files: FileList) => Promise<void>;     // This is defined externally, but we want to limit external dependencies
}

//==============================================================================
// Function Component
//==============================================================================
@observer
class UploadBox extends React.Component<UploadBoxProps> {

    @observable private isDropping: Boolean = false;

    //----------------------------------------------------------
    //----------------------------------------------------------
    constructor(props: UploadBoxProps) {
        super(props);

        this.dragEnter = this.dragEnter.bind(this);
        this.dragLeave = this.dragLeave.bind(this);
        this.drop = this.drop.bind(this);
    }

    //----------------------------------------------------------
    //----------------------------------------------------------
    public render() {
        return (
            <div
                className={classnames(this.props.className, { 'upload-box-dropping': this.isDropping })}
                onDragOver={this.dragEnter} onDragEnter={this.dragEnter}
                onDragEnd={this.dragLeave} onDragLeave={this.dragLeave}
                onDrop={this.drop}
            >
                {this.props.children}
            </div>
        );
    }

    //----------------------------------------------------------
    //----------------------------------------------------------
    private dragEnter(e: React.DragEvent<HTMLDivElement>) {
        e.preventDefault();
        this.isDropping = true;
    }

    //----------------------------------------------------------
    //----------------------------------------------------------
    private dragLeave(e: React.DragEvent<HTMLDivElement>) {
        e.preventDefault();
        this.isDropping = false;
    }

    //----------------------------------------------------------
    //----------------------------------------------------------
    private drop(e: React.DragEvent<HTMLDivElement>) {
        this.dragLeave(e);      // Includes preventDefault
        const filesToUpload = e.dataTransfer.files;

        if (e.dataTransfer.types.includes('Files') && filesToUpload.length) {
            void this.props.initiateUpload(filesToUpload);
        }
    }

}

export default UploadBox;
