//==============================================================================
//==============================================================================
import * as React from 'react';

import * as Msdyn365 from '@msdyn365-commerce/core';

import { IUploadManagerViewProps, UploadableFile, UploadableFiles, UploadHandler, UploadProgress, RemoveFileHandler, formatFileSize, UploadError } from './upload-manager';
import { IHeadingData, IUploadManagerResources } from './upload-manager.props.autogenerated';

import UploadButton from './components/upload-button';
import UploadBox from './components/upload-box';
import ProgressBar from './components/progress-bar';

//==============================================================================
//==============================================================================
const BASE_CLASS = 'upload-manager';
const cls = (fragment: string) => `${BASE_CLASS}__${fragment}`;

//==============================================================================
//==============================================================================

//==========================================================
// Construct the header node
//==========================================================
function renderHeader(heading: IHeadingData): React.ReactNode {

    return heading.text && (
        <Msdyn365.Text
            className={cls('heading')}
            tag={heading.tag || 'h1'}
            text={heading.text}
        />
    );
}

//==========================================================
// Construct the instructions node (left-most of 3 columns on desktop)
//==========================================================
function renderInstructions(instructions: Msdyn365.RichText): React.ReactNode {

    return (
        <Msdyn365.RichTextComponent
            text={instructions}
            className={cls('instructions')}
        />
    );
}

//==========================================================
// Construct the upload target node (middle column on desktop)
//==========================================================
function renderUploadTarget(resources: IUploadManagerResources, acceptedFormats: string[], initiateUpload: UploadHandler): React.ReactNode {

    const formatString = acceptedFormats.length ? acceptedFormats.join(', ') : '';

    return (
        <div className={cls('target-column')}>

            <UploadBox className={cls('target')} initiateUpload={initiateUpload}>
                <div className={cls('target-icon')}></div>
                <div className={cls('target-drag-and-drop')}>{resources.dragAndDrop}</div>
                <div className={cls('target-or')}>{resources.or}</div>
                <UploadButton
                    className={cls('target-button')}
                    acceptedExtensions={acceptedFormats}
                    buttonText={resources.browseFiles}
                    initiateUpload={initiateUpload}
                />
            </UploadBox>

            {formatString &&
                <div className={cls('target-formats')}>
                    {resources.acceptedFormats}: {formatString}
                </div>
            }

        </div>
    );
}

//==========================================================
// Construct the uploaded files node (right column on desktop)
//==========================================================
function renderUploadedFiles(resources: IUploadManagerResources, fileList: UploadableFiles, progress: UploadProgress[], removeFile: RemoveFileHandler): React.ReactNode {

    return (
        <div className={cls('uploads-column')}>

            <h6 className={cls('uploads-header')}>
                {resources.uploadedDocuments}
            </h6>

            {!!(fileList?.length) &&
                <div className={cls('uploads')}>
                    {fileList.map(file => renderFile(file, removeFile))}
                </div>
            }

            {!fileList?.length &&
                <div className={cls('uploads-none')}>
                    {resources.noUploads}
                </div>
            }

            {!!progress.length &&
                <div className={cls('uploads-progress')}>
                    {progress.map(file => renderProgress(file))}
                </div>
            }

        </div>
    );
}

//==========================================================
// Construct a node for a single uploaded file
//==========================================================
function renderFile(file: UploadableFile, removeFile: RemoveFileHandler) {
    return (
        <div key={file.name} className={cls('uploads-entry')}>
            <div className={cls('uploads-entry-name')}>{file.name}</div>
            <div className={cls('uploads-entry-remove')} onClick={ev => removeFile(file)}></div>
            <div className={cls('uploads-entry-size')}>{formatFileSize(file.size)}</div>
        </div>
    );
}

//==========================================================
// Construct a node for a single uploaded file
//==========================================================
function renderProgress(file: UploadProgress) {
    return (
        <div key={file.name} className={cls('uploads-progress-entry')}>
            <div className={cls('uploads-progress-entry-name')}>{file.name}</div>
            <ProgressBar
                className={cls('uploads-progress-entry-status')}
                status={file}
            />
        </div>
    );
}

//==========================================================
// Displays any errors generated by the upload module
//==========================================================
function renderErrors(resources: IUploadManagerResources, errors: UploadError[]) {
    return (
        <div className={cls('error-container')}>

            {errors.map((error, idx) => (
                <>
                    {error.filename &&
                        <div key={idx} className={cls('error')}>
                            <span className={cls('error-file')}>{error.filename}:</span> <span className={cls('error-message')}>{error.message}</span>
                        </div>
                    }

                    {!error.filename &&
                        <div key={idx} className={cls('error')}>
                            <span className={cls('error-message')}>{error.message}</span>
                        </div>
                    }
                </>
            ))}

        </div>
    );
}

//==========================================================
// The main render function
//==========================================================
export default (props: IUploadManagerViewProps) => {
    return (
        <div className={BASE_CLASS}>
            {props.config.heading && renderHeader(props.config.heading)}
            <div className={cls('container')}>
                {props.config.instructions && renderInstructions(props.config.instructions)}
                {renderUploadTarget(props.resources, props.config.acceptedFormats || [], props.initiateUpload)}
                {renderUploadedFiles(props.resources, props.fileList, props.progress, props.removeFile)}
                {!!(props.errors?.length) && renderErrors(props.resources, props.errors)}
            </div>
        </div>
    );
};
