//==============================================================================
// Creates a styleable file upload button, replacing the ugly standard button
//
// Based on code by Masa Kudamatsu
// https://medium.com/web-dev-survey-from-kyoto/how-to-customize-the-file-upload-button-in-react-b3866a5973d8
//==============================================================================
import * as React from 'react';

//==============================================================================
//==============================================================================
export interface UploadButtonProps {
    className: string;
    acceptedExtensions: string[];
    buttonText: string;
    initiateUpload: (files: FileList) => Promise<void>;     // This is defined externally, but we want to limit external dependencies
}

//==============================================================================
// Function Component
//==============================================================================
const UploadButton: React.FC<UploadButtonProps> = (props) => {

    // Create a reference to the hidden file input element
    const fileInputRef = React.useRef<HTMLInputElement>(null);

    // Programatically click the hidden file input element
    // when the Button component is clicked
    const handleClick: React.MouseEventHandler<HTMLButtonElement> = event => {
        fileInputRef.current?.click();
    };

    // Call a function (passed as a prop from the parent component)
    // to handle the user-selected file
    const handleChange: React.ChangeEventHandler<HTMLInputElement> = event => {
        if (event.target.files?.length) {
            void props.initiateUpload(event.target.files);

            // Clear the input's value so selecting the same file will trigger a change event
            fileInputRef.current!.value = '';
        }
    };

    // Convert the list of accepted file extensions to the format required by the file input tag
    const formattedExtensions = props.acceptedExtensions.map(entry => `.${entry}`).join(',');

    return (
        <>
            <button className={props.className} onClick={handleClick}>
                {props.buttonText}
            </button>

            <input
                type="file"
                multiple
                accept={formattedExtensions}
                ref={fileInputRef}
                onChange={handleChange}
                style={{ display: 'none' }}
            />
        </>
    );
};

export default UploadButton;