//==============================================================================
// Manages file uploads
//
// The definition file contains references to document uploads for onboarding,
// but this is a completely generic upload system.
//
// This works by requesting secure upload tokens from an external source, then
// using those tokens to upload the file to cloud storage.
//
// The actual upload mechanism will be isolated as much as possible, to allow
// for easy retargeting. The initial version targets Azure blob storage, but
// switching to any other provider that works with access tokens, such as
// AWS S3, should be very simple.
//==============================================================================
import * as React from 'react';
import { observer } from 'mobx-react';
import { observable, IObservableArray } from 'mobx';

import { IFileWithSas, ISASEntity } from '../../actions/DataServiceEntities.g';
import { generateUrisAsync } from '../../actions/DataActionExtension.g';

import { UploadNotificationHandler } from '../umg-onboarding-form';

import { IUploadManagerData } from './upload-manager.data';
import { IUploadManagerProps } from './upload-manager.props.autogenerated';

//==============================================================================
// INTERFACES
//==============================================================================
interface UploadManagerRealProps extends IUploadManagerProps<IUploadManagerData> {
    changeHandler: UploadNotificationHandler;
}

export type UploadHandler = (files: FileList) => Promise<void>;
export type RemoveFileHandler = (file: UploadableFile) => void;

export interface IUploadManagerViewProps extends UploadManagerRealProps {
    initiateUpload: UploadHandler;
    removeFile: RemoveFileHandler;
    fileList: UploadableFiles;
    progress: UploadProgress[];
    errors: UploadError[];
}

// Keep track of uploaded files
export interface UploadableFile {
    name: string;          // Base filename
    size: number;          // File size
    file?: File;            // Reference to the upload file object, needed for the actual upload
    url?: string;           // This is the secure URL to upload the file
    identifier?: string;    // After upload completes, this is the unique identifier to access the file
}
export type UploadableFiles = UploadableFile[];

export interface UploadProgress {
    name: string;       // Filename
    size: number;       // Not really needed, but could be useful
    progress: number;   // 0-1
}

export interface UploadError {
    filename?: string;
    message: string;
}

//==============================================================================
// CLASS DEFINITION
//==============================================================================
/**
 * UploadManager component
 * @extends {React.Component<UploadManagerRealProps>}
 */
@observer
class UploadManager extends React.Component<UploadManagerRealProps> {

    @observable private fileList: UploadableFiles = [];
    @observable private errors: UploadError[] = [];
    @observable private progress: UploadProgress[] = [];

    //----------------------------------------------------------
    //----------------------------------------------------------
    constructor(props: UploadManagerRealProps) {
        super(props);

        this.initiateUpload = this.initiateUpload.bind(this);
        this.removeFile = this.removeFile.bind(this);
    }

    //----------------------------------------------------------
    //----------------------------------------------------------
    public render(): JSX.Element | null {

        // An array iterator or length check is required for an observable array to cause re-renders
        // @ts-expect-error
        const bogus = this.errors.length + this.fileList.length + this.progress.length;

        const viewProps: IUploadManagerViewProps = {
            ...this.props,
            fileList: this.fileList,
            errors: this.errors,
            initiateUpload: this.initiateUpload,
            removeFile: this.removeFile,
            progress: this.progress,
        };

        return this.props.renderView(viewProps);
    }

    //----------------------------------------------------------
    // Centralize (but stupidly simple) error manager
    //----------------------------------------------------------
    private addError(error: string, filename?: string): void {
        this.errors.push({
            message: error,
            filename
        });
    }

    //----------------------------------------------------------
    // The upload was initiated via click or drag and drop
    //----------------------------------------------------------
    private async initiateUpload(files: FileList): Promise<void> {

        // Clear old errors
        this.errors = [];

        // Verify each file
        const validFiles = this.validateRequestedUploads(files);

        // Request SAS tokens for each file
        const secureURLs = await this.requestSecureUploadURLs(validFiles);

        // Abort if none
        if (!secureURLs) { return; }

        // Upload files
        await this.uploadFiles(secureURLs);

        // Inform our imperial overlord about changes to the file list
        this.props.changeHandler(this.fileList);
    }

    //----------------------------------------------------------
    // Verifies all of the requested file uploads against extension
    // We could also validate against max upload size, but we
    // don't have that info. It's easier to let retail server
    // handle that check.
    //----------------------------------------------------------
    private validateRequestedUploads(files: FileList): UploadableFile[] {

        // Convert the list of allowed extensions to lowercase
        const validExtensions = (this.props.config.acceptedFormats || []).map(entry => entry.toLowerCase());

        // Convert from FileList to an array
        const simplifiedList: UploadableFile[] = [];
        for (let i = 0; i < files.length; i++) {
            simplifiedList.push({
                name: files[i].name,
                size: files[i].size,
                file: files[i]
            });
        }

        // Add valid files to the list
        return simplifiedList.filter(file => {
            const extension = file.name.split('.').pop();

            // This code could be a lot shorter, but we want to think about every possibility and set the correct messaging based on the case

            // All extensions are accepted
            if (!validExtensions.length) {
                return true;
            }

            // No extension supplied
            if (!extension) {
                this.addError(this.props.resources.unknownFileType, file.name);
                return false;
            }

            // Extension supplied, but not in the whitelist
            if (!validExtensions.includes(extension.toLowerCase())) {
                this.addError(this.props.resources.invalidFileExtension, file.name);
                return false;
            }

            // There was an extension, and it's a valid one
            return true;
        });
    }

    //----------------------------------------------------------
    // Performs the retail server call to fetch secure upload URLs
    //----------------------------------------------------------
    private async requestSecureUploadURLs(fileList: UploadableFile[]): Promise<UploadableFile[] | undefined> {

        // Convert file list to request format
        const requestPayload: ISASEntity = {
            Id: 0,
            UploadFiles: fileList.map(entry => ({
                Name: entry.name,
                Size: Math.ceil(entry.size / 1024),     // The server expects kilobytes and doesn't accept decimals
            })),
        };

        // Perform the request
        try {
            const result = await generateUrisAsync({ callerContext: this.props.context.actionContext }, requestPayload);
            const mappedResults: UploadableFile[] = this.mapResults(result.FilesWithSas || [], fileList);

            // Handle any errors -- @FIXME/dg: Switch to error code support
            result.ErrorFiles!.forEach(error => {
                error.ValidationErrors!.forEach(entry => this.addError(this.errorCodeToString(entry), error.Name));
            });

            return mappedResults;
        }
        catch (err) {
            this.addError(this.props.resources.unknownError);
            return [];
        }
    }

    //----------------------------------------------------------
    //----------------------------------------------------------
    private errorCodeToString(code: string): string {
        const { resources, config } = this.props;

        // Convert between error codes and error messages in resources
        const errorCodeMap = {
            ERR_INVALID_EXTENSION: resources.invalidFileExtension,
            ERR_FILE_SIZE_ZERO: resources.uploadFileTooSmall,
            ERR_FILE_SIZE_EXCEEDS_MAX_LIMIT: resources.uploadFileTooBig,
        };

        const message = errorCodeMap[code] || resources.unknownError;

        return message
            .replace('{s}', formatFileSize((config.maxFileSize || 5120) * 1024));
    }

    //----------------------------------------------------------
    // Combines a file list with name and size, and a secure URL
    // list with name and URL
    //----------------------------------------------------------
    private mapResults(urlList: IFileWithSas[], fileList: UploadableFile[]) {
        const output: UploadableFile[] = [];

        // Step through the list of successful results
        urlList.forEach(entry => {

            // Find the matching record in our file list
            const match = fileList.find(file => file.name === entry.Name);
            if (!match || !entry.SasUri) {
                return console.error('NO MATCH FOUND!', entry.Name);       // @FIXME/dg: Improve error handling after dev complete
            }

            // Add the URL to the UploadableFile record
            match.url = entry.SasUri;

            // Add the UploadableFile record to our filtered output
            output.push(match);
        });

        return output;
    }

    //----------------------------------------------------------
    // Uploads multiple files at once, in parallel
    // This could have issues with a lot of files at once,
    // and might be best with a capped parallel pattern instead.
    // However, I think the browser will enforce that cap on its
    // own, and it's very unlikely more than a couple files will
    // be uploaded at once.
    //----------------------------------------------------------
    private async uploadFiles(filesToUpload: UploadableFile[]) {

        // Initiate uploads and wait for all to complete (successfully or not)
        const promises = filesToUpload.map(entry => this.doUpload(entry));
        const result = await Promise.allSettled(promises);

        // Process the results
        result.forEach((entry, index) => {

            // Regardless of the result, clear the entry from the progress list
            this.clearProgressEntry(filesToUpload[index].name);

            if (entry.status === 'rejected') {
                this.addError(this.props.resources.uploadFailed, filesToUpload[index].name);
            }
            else {
                const file = filesToUpload[index];
                this.fileList.push({
                    name: file.name,
                    size: file.size,
                    identifier: file.url!.split('?')[0]     // Strip the search string. Consider removing 'https://' as well, but it's not that important
                });
            }
        });
    }

    //----------------------------------------------------------
    // Initiates upload of a single file
    //----------------------------------------------------------
    private async doUpload(file: UploadableFile) {

        const promise = new Promise((resolve, reject) => {

            // Init the file upload
            const fd = new FormData();
            fd.set('Content-Type', file.file!.type);
            fd.set('file', file.file!);                               // This must be last!
            var xhr = new XMLHttpRequest();

            // Generate a progress record
            const progressRecord = this.createProgressEntry(file.name, file.size);

            // Attach listeners
            xhr.upload.addEventListener("progress", ev => progressRecord.progress = (ev.lengthComputable ? (ev.loaded / ev.total) : 0), false);
            xhr.addEventListener("error", err => { reject('Error'); }, false);
            xhr.addEventListener("abort", () => reject('Aborted'), false);
            xhr.addEventListener("load", resp => (xhr.status >= 200 && xhr.status < 300) ? resolve(file) : reject('Failed'), false);    // We need to check the response code to determine if it completed successfully or not

            // Finalize the request
            xhr.open("PUT", file.url!);
            xhr.setRequestHeader('x-ms-blob-type', 'BlockBlob');        // Required header

            // Perform the request
            xhr.send(fd);
        });

        return promise;
    }

    //----------------------------------------------------------
    // Creates a progress record, which the view uses to render
    // an upload progress bar
    //----------------------------------------------------------
    private createProgressEntry(filename: string, size: number): UploadProgress {

        const progress: UploadProgress = {
            name: filename,
            size: size,
            progress: 0
        };

        // It shouldn't be possible for two duplicate files unless the user
        // is pulling from different folders. In that case, the whole system will implode.
        // SAS tokens are based on unique names.
        // We'll still return an (orphaned) record for the progress event to work with
        if (this.progress.find(entry => entry.name === filename)) {
            console.warn(`Duplicate progress record found for ${filename}!`);
        }
        else {
            this.progress.push(progress);
        }

        // progress and the last entry of this.progress are NOT the same
        // this.progress is observable, so mobx cloned it and wrapped it
        return this.progress[this.progress.length - 1];
    }

    //----------------------------------------------------------
    // Removes the progress record for an uploading file
    // Occurs when upload completes, whether success or not
    //----------------------------------------------------------
    private clearProgressEntry(filename: string): void {

        // Filter out entries with the matching filename. There better only be one!
        const filtered = this.progress.filter(entry => entry.name !== filename);

        // With mobx, we can't just reassign our array or the observability will be lost
        // We need to use replace instead, which requires a bit of type manipulation
        (this.progress as IObservableArray).replace(filtered);
    }

    //----------------------------------------------------------
    // "Removes" an upload by simply forgetting about it
    // The file isn't deleted from cloud storage -- it's left
    // as an orphan to be cleaned up by cloud storage policy
    //----------------------------------------------------------
    public removeFile(file: UploadableFile): void {
        // Find the entry
        const index = this.fileList.indexOf(file);

        if (index === -1) {
            return console.warn('Unable to find file reference!', file.name);
        }

        // Remove it from our list
        this.fileList.splice(index, 1);

        // Let our overlord know about the change
        this.props.changeHandler(this.fileList);
    }
}

//==========================================================
//==========================================================
export function formatFileSize(size: number): string {

    // List of possible units. Add to the list to support gigabytes, terrabytes, etc.
    const units = ['bytes', 'kb', 'mb'];

    // Step through the units until we find the right one
    for (const unit of units) {
        if (size < 1024) {

            // Note that I'm using floor instead of round, which is a matter of preference
            // For 1 bytes less than 1MB, floor will show "1023.9kb" and round will show "1024kb" (not "1mb")
            return `${Math.floor(size * 10) / 10}${unit}`;
        }

        size /= 1024;
    }

    // There was no match, so use the largest unit in the list
    // Also note we have to undo the last divide by 1024
    // I decided to use round here, even though it's not consistent
    // Floor only makes sense when near a unit boundary
    return `${Math.round(size * 10 * 1024) / 10}${units[units.length - 1]}`;
}


export default UploadManager;
